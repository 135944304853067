*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    cursor: auto;
}

.cursor {
    cursor: pointer;
}

.zoom {
    cursor: zoom-in;
}

.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

body {
    overflow-x: hidden;
}
h1,h2 {
    font-family: 'Inter', sans-serif;
}
h3,h4,h5 {
    font-family: 'Inter', sans-serif;
}
p {
    font-family: 'Inter', sans-serif;
}
a {
    /*font-family: 'Noto Sans', sans-serif*/
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 0.8em;
    color: white;
    opacity: 0.8;
    letter-spacing: .1em;
}
a:hover {
    color: white;
    text-decoration: none;
}
a::after {
    color: white;
    content: '';
    display: block;
    width: 0;
    height: 0.6px;
    background: white;
    transition: width ease-in-out .2s;
}
a:hover::after {
    color: white;
    text-decoration: none;
    border-bottom: 0.5px solid;
    width: 100%;
}

section {
    height: 100vh;
    padding-top: 15%;
}
.intro {
    color: white;
    overflow: hidden;
}
.navbar {
    padding: 16px 32px 0 32px;
}
.intro-title {
    font-size: 4.5em;
}
.intro-sub {
    margin-top: 2.5%;
}
.divider {
    border-bottom: 1px solid;
    width: 60%;
    margin-top: 5%;
}
.intro-p {
    margin-top: 5%;
}

/* three js shit */
canvas {
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -9999;
}
  /* end three js */
.sector2 {
    position: relative;
}

.menus {
    width: 500px;
    height: auto;
}

.mr-auto {
    opacity: 0;
    transition: all 300ms ease-in;
}

.ml-auto {
    opacity: 0;
    transition: all 300ms ease-in;
}


.projects {
    margin-top: 0;
    right: 220px;
    position: absolute;
}

.about {
    right: 136px;
    position: absolute;

    transition: all 300ms ease-in;
}

.contact {
    right: 40px;
    opacity: 0;
    position: absolute;
    transition: all 300ms ease-in;
}


/* Loading */
.loading-circle {
    position: fixed;
    top: 47%;
    left: 47%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid rgba(0, 0, 0, 0.1);
    animation: spin 1s linear infinite;
    overflow: hidden;
  }
  
  .loading-circle::before {
    content: "";
    position: absolute;
    top: -4px;
    left: 50%;
    width: 4px;
    height: 50%;
    background-color: #fff;
    /* Add box-shadow for the glow effect */
    box-shadow: 0 0 5px #fff, 0 0 10px #fff;
    transform-origin: bottom center;
    animation: spin 1s linear infinite;
  }


  #popup-iframe {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

